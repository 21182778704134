import {
  InMemoryCache,
  ApolloLink,
  Observable,
  ApolloClient,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from 'apollo-utilities';
import { createUploadLink } from 'apollo-upload-client';
import { API_URL, LS } from './consts';
import history from './history';
import formatDateVariable from './_helpers/formatDateVariable';
import * as Sentry from '@sentry/browser';
import App from './App/App';
import gql from 'graphql-tag/src';
import { relayStylePagination } from '@apollo/client/utilities';

const __DEV__ = process.env.NODE_ENV === 'development';
let client;

// Token
// =========================================================================

export const Token = {
  get() {
    return window.localStorage.getItem(LS.TOKEN);
  },
  set(value) {
    window.localStorage.setItem(LS.TOKEN, value);
  },
  clear() {
    window.localStorage.removeItem(LS.TOKEN);
  },
};

// Cache
// =========================================================================

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        allValdTests: relayStylePagination(),
      },
    },
  },
});
// cache.writeData({
//   data: {
//     token: Token.get(),
//     loggedIn: !!Token.get(),
//   },
// });
cache.writeQuery({
  query: gql`
    query GetToken {
      token
      loggedIn
    }
  `,
  data: {
    token: Token.get(),
    loggedIn: !!Token.get(),
  },
});

// Error Link
// =========================================================================

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, response, ...other }) => {
    // TODO: Only console log in dev mode (otherwise send to Sentry)?

    if (graphQLErrors && __DEV__)
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );

    if (networkError && __DEV__)
      console.log(`[Network error]: ${networkError}`);

    if (
      graphQLErrors &&
      [
        'ValidateUser',
        'RegisterPerson',
        'Reset',
        'UpdatePassword',
        'DeleteFolderPerson',
        'UpdatePersonEmail',
      ].indexOf(operation.operationName) === -1
    ) {
      if (__DEV__) console.log(operation);

      graphQLErrors.forEach((e) => {
        const { message, locations, path } = e;

        // Ignore permission denied errors if we're not logged in
        if (
          message.indexOf('permission denied') > -1 &&
          !!localStorage.getItem(LS.TOKEN)
        )
          return;

        if (message.indexOf('jwt expired') > -1) return App.logout();

        const err = new Error(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations
          )}, Path: ${path}`
        );

        if (__DEV__) console.error(err);
        else Sentry.captureException(err);
      });
    }

    if (networkError) {
      // eslint-disable-next-line default-case
      switch (networkError.statusCode) {
        case 401:
        case 403:
          App.logout();
      }
    }
  }
);

// Request Link
// =========================================================================

const Timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const request = async (operation) => {
  const token = Token.get();

  const headers = { Timezone };

  if (token) headers.Authorization = `Bearer ${token}`;

  operation.setContext({ headers });

  Object.keys(operation.variables).forEach((key) => {
    operation.variables[key] = formatDateVariable(operation.variables[key]);
  });

  if (!__DEV__) {
    const variables = operation.variables
      ? Object.keys(operation.variables)
          .filter(
            (key) =>
              key.indexOf('password') === -1 && key.indexOf('email') === -1
          )
          .reduce(
            (a, b) => ({
              ...a,
              [b]: operation.variables[b],
            }),
            {}
          )
      : null;

    Sentry.configureScope((scope) => {
      scope.setExtra('request', {
        operationName: operation.operationName,
        variables,
      });
    });
  }
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle;
      Promise.resolve(operation)
        .then((oper) => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => handle && handle.unsubscribe();
    })
);

// Upload Link
// =========================================================================

const parseHeaders = (rawHeaders) => {
  const headers = new Headers();
  // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
  // https://tools.ietf.org/html/rfc7230#section-3.2
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');

  preProcessedHeaders.split(/\r?\n/).forEach((line) => {
    const parts = line.split(':');
    const key = parts.shift().trim();

    if (key) {
      const value = parts.join(':').trim();
      headers.append(key, value);
    }
  });

  return headers;
};

const uploadFetch = (url, options) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onload = () => {
      const opts = {
        status: xhr.status,
        statusText: xhr.statusText,
        headers: parseHeaders(xhr.getAllResponseHeaders() || ''),
      };

      opts.url =
        'responseURL' in xhr
          ? xhr.responseURL
          : opts.headers.get('X-Request-URL');

      const body = 'response' in xhr ? xhr.response : xhr.responseText;

      resolve(new Response(body, opts));
    };

    xhr.onerror = () => {
      reject(new TypeError('Network request failed'));
    };

    xhr.ontimeout = () => {
      reject(new TypeError('Network request failed'));
    };

    xhr.open(options.method, url, true);

    Object.keys(options.headers).forEach((key) => {
      xhr.setRequestHeader(key, options.headers[key]);
    });

    if (xhr.upload) xhr.upload.onprogress = options.onProgress;

    xhr.send(options.body);
  });
};

const customFetch = (uri, options) => {
  if (options.useUpload) return uploadFetch(uri, options);

  return fetch(uri, options);
};

/**
 * ```js
 * mutate({
 *    variables: {
 *      file
 *    },
 *    context: {
 *      fetchOptions: {
 *        useUpload: true,
 *        onProgress: e => {
 *          setProgress(e.loaded / e.total);
 *        }
 *      }
 *    }
 *  })
 * ```
 *
 * @type {ApolloLink}
 */
const uploadLink = createUploadLink({
  uri: API_URL,
  fetch: customFetch,
});

// Network Link
// =========================================================================

// HTTP Link
// -------------------------------------------------------------------------

const httpLink = uploadLink;

// Web Socket Link
// -------------------------------------------------------------------------

const wsLink = new WebSocketLink({
  uri: API_URL.replace('https://', 'wss://'),
  options: {
    reconnect: true,
    connectionParams: () => {
      const token = Token.get();

      if (token) return { Authorization: `Bearer ${token}` };
      else return {};
    },
  },
});

export const subscriptionClient = wsLink.subscriptionClient;

// The Link
// -------------------------------------------------------------------------

const networkLink = ApolloLink.split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

// Client
// =========================================================================

client = new ApolloClient({
  link: ApolloLink.from([errorLink, requestLink, networkLink]),
  cache,
  connectToDevTools: true,
  ssrMode: false,
});

client.onResetStore(() => {
  Token.clear();

  // cache.writeData({
  // 	data: {
  // 		token: null,
  // 		loggedIn: false,
  // 	},
  // });
  App.logout();

  history.push('/');
});

client.defaultOptions = {
  watchQuery: {
    errorPolicy: 'all',
  },
  query: {
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

export default client;
