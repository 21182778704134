import React, { Fragment } from 'react';
import Permissions from '../_components/Permissions';
import { ICONS, Link } from './App';
import gql from 'graphql-tag';
import { useSubscription, useQuery } from '@apollo/client';
import useFieldwizEnabled from '../_hooks/useFieldwizEnabled';

export const UNREAD_MESSAGES_QUERY = gql`
  query GetUnreadMessages {
    unreadMessages: allChats(
      filter: { unread: { greaterThan: 0 }, videoAnalysis: { isNull: true } }
    ) {
      totalCount
      nodes {
        id
      }
    }
  }
`;

const UNREAD_VIDEO_CHATS_SUBSCRIPTION = gql`
  subscription GetUnreadMessages {
    unreadVideoChats: allChats(
      filter: { unread: { greaterThan: 0 }, videoAnalysis: { isNull: false } }
    ) {
      totalCount
    }
  }
`;

const NormieNav = ({ application }) => {
  // FIXME: temporarily disable polling to avoid spamming in Sentry until we found the problem...
  // [GraphQL error]: Message: permission denied for relation chat, Location: [{"line":2,"column":3}], Path: unreadMessages
  const { data: unreadMessagesData } = useQuery(UNREAD_MESSAGES_QUERY, {
      //  pollInterval: process.env.NODE_ENV === 'development' ? void 0 : 10000,
    }),
    { data: unreadVideoChatsData } = useSubscription(
      UNREAD_VIDEO_CHATS_SUBSCRIPTION
    );

  const hasUnreadMessages = false,
    // unreadMessagesData &&
    // unreadMessagesData.unreadMessages &&
    // unreadMessagesData.unreadMessages.totalCount > 0,
    hasUnreadVideoChats =
      unreadVideoChatsData &&
      unreadVideoChatsData.unreadMessages &&
      unreadVideoChatsData.unreadMessages.totalCount > 0;

  const wiz = useFieldwizEnabled();

  return (
    <Fragment>
      <Permissions can="access:dashboard">
        <Link to="/" exact title="Dashboard">
          {ICONS.DASHBOARD}
          <span>Dashboard</span>
        </Link>
      </Permissions>

      <Permissions can="access:athletes">
        <Link to="/athletes" title="Athletes">
          {ICONS.ATHLETES}
          <span>Athletes</span>
        </Link>
      </Permissions>

      <Permissions can="access:schedule">
        <Link to="/schedule" title="Schedule">
          {ICONS.SCHEDULE}
          <span>Schedule</span>
        </Link>
      </Permissions>

      <Permissions can="access:injuries">
        <Link to="/injuries" title="Injury Analysis">
          {ICONS.INJURY}
          <span>Injury Analysis</span>
        </Link>
      </Permissions>

      <Permissions can="access:loadWellness">
        <Link to="/load" title="Load">
          {ICONS.WELLNESS}
          <span>Load</span>
        </Link>
      </Permissions>

      <Permissions can="access:loadWellness">
        <Link to="/wellness" title="Wellness">
          {ICONS.WELLNESS}
          <span>Wellness</span>
        </Link>
      </Permissions>

      <Permissions can="access:calibrate">
        <Link to="/profiling" title="Profiling">
          {ICONS.ATHLETES}
          <span>Profiling</span>
        </Link>
      </Permissions>

      <Permissions can="access:video">
        <Link to="/video" title="Video Analysis">
          {ICONS.VIDEO}
          <span>Video Analysis</span>
          {hasUnreadVideoChats && <i />}
        </Link>
      </Permissions>

      {application?.parentFeatures?.['/nomination']?.isEnabled && (
        <Link to="/nomination" title="Nomination">
          {ICONS.ATHLETES}
          <span>Nomination</span>
        </Link>
      )}

      <Permissions can="access:forms">
        <Link to="/forms" title="Forms">
          {ICONS.TEMPLATES}
          <span>Forms</span>
        </Link>
      </Permissions>

      <Permissions can="access:reports">
        <Link to="/screening" title="Screening Reports">
          {ICONS.REPORTS}
          <span>Screening Reports</span>
        </Link>
      </Permissions>

      <Permissions can="access:files">
        <Link to="/files" title="Files">
          {ICONS.FILES}
          <span>Files</span>
        </Link>
      </Permissions>

      <Permissions can="access:messages">
        <Link to="/messages" title="Messages">
          {ICONS.MESSAGES}
          <span>Messages</span>
          {hasUnreadMessages && <i />}
        </Link>
      </Permissions>

      {/*<Permissions can="access:asi">
				<Link to="/asi" title="ASI">
					{ICONS.WELLNESS}
					<span>ASI</span>
				</Link>
			</Permissions>*/}

      <Permissions can="access:settings">
        <Link to="/settings" title="Settings">
          {ICONS.SETTINGS}
          <span>Settings</span>
        </Link>
      </Permissions>
    </Fragment>
  );
};

export default NormieNav;
