// @flow
import React, { PureComponent } from 'react';
import styles from './Spinner.less';

export default class Spinner extends PureComponent {
  // Properties
  // =========================================================================

  props: {
    size?: 'small' | 'medium' | 'large',
    show?: boolean,
    centre?: boolean, // If true, the spinner will be offset by 50% x/y
    block?: boolean,
    fixed?: boolean,
    noStretch?: boolean,
    className?: string,
  };

  static defaultProps = {
    size: 'medium',
  };

  // Render
  // =========================================================================

  render() {
    const {
      size,
      show,
      centre,
      block,
      fixed,
      noStretch,
      className = '',
    } = this.props;

    const cls = [styles.spinner, styles[size], className];
    if (show) cls.push(styles.show);
    if (centre) cls.push(styles.centre);

    const spin = <i data-spinner className={cls.join(' ')} />;

    if (block || fixed) {
      const blockCls = [styles.block];
      if (noStretch) blockCls.push(styles.noStretch);
      if (fixed) blockCls.push(styles.fixed);

      return <div className={blockCls.join(' ')}>{spin}</div>;
    }

    return spin;
  }
}
