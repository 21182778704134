import getApplication from './getApplication';

async function authAndRedirect(token, url, path = '') {
  const frame = await new Promise((resolve) => {
    const frame = document.createElement('iframe');
    frame.src = url + '/auth.html';
    frame.setAttribute('hidden', true);
    frame.addEventListener('load', () => {
      resolve(frame);
    });
    document.body.appendChild(frame);
  });

  frame.contentWindow.postMessage(
    {
      action: 'auth',
      value: token,
    },
    url
  );

  frame.contentWindow.postMessage(
    {
      action: 'origin',
      value: getApplication(),
    },
    url
  );

  window.location.href = url + path;
}

export default authAndRedirect;
