import React, { Fragment, useState } from 'react';
import styles from './NewsFeed.less';
import Modal from 'react-modal';
import Item, { FEED_ITEM_QUERY } from '../NewsFeedItem/NewsFeedItem';
import Spinner from '../Spinner/Spinner';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';
import ErrorList from '../ErrorList/ErrorList';
import Ago from '../../_helpers/ago';
import Divider from '../Divider/Divider';
import NotificationsActive from '../../_icons/notifications/Active';
import NotificationsUnread from '../../_icons/notifications/Unread';
import NotificationsInactive from '../../_icons/notifications/Inactive';

const GET_FEED_QUERY = gql`
  query GetNewsFeed {
    feed: allActivities(
      filter: { isGlobal: { equalTo: true } }
      first: 20
      orderBy: CREATED_AT_DESC
    ) {
      nodes {
        ...FeedItemQuery
      }
    }
    unreadNews: allActivities(
      filter: { read: { equalTo: false }, isGlobal: { equalTo: true } }
    ) {
      totalCount
    }
  }
  ${FEED_ITEM_QUERY}
`;

const MARK_AS_READ_MUTATION = gql`
  mutation MarkNewsRead {
    markActivityRead(input: { clientMutationId: null }) {
      query {
        unreadNews: allActivities(filter: { read: { equalTo: false } }) {
          totalCount
        }
      }
    }
  }
`;

function getPrevDay(date) {
  return date.split('T')[0];
}

export default function NewsFeed() {
  const [show, setShow] = useState(false);

  const { loading, error, data, refetch } = useQuery(GET_FEED_QUERY);
  const [markAsRead] = useMutation(MARK_AS_READ_MUTATION);

  const hasUnreadNotifications =
    data && data.unreadNews && data.unreadNews.totalCount > 0;

  const onOpen = async () => {
    if (show) {
      onClose();
      return;
    }

    setShow(true);
    data && !loading && !error && refetch && (await refetch());
    markAsRead();
  };

  const onClose = () => {
    setShow(false);
  };

  return (
    <>
      <button className={styles['feed-btn']} onClick={onOpen}>
        {show
          ? NotificationsActive
          : hasUnreadNotifications
          ? NotificationsUnread
          : NotificationsInactive}
      </button>
      <Modal
        isOpen={show}
        onRequestClose={onClose}
        className={{
          base: styles.modal,
          afterOpen: styles.modalOpen,
          beforeClose: styles.modalClose,
        }}
        closeTimeoutMS={300}
        overlayClassName={{
          base: styles.overlay,
          afterOpen: styles.overlayOpen,
          beforeClose: styles.overlayClose,
        }}
      >
        <h3>News Feed</h3>

        {loading && <Spinner show block centre />}
        {error && <ErrorList errors={[error]} />}
        {data &&
          data.feed?.nodes.map((item, i) => {
            const prev = i > 0 ? data.feed.nodes[i - 1] : null;
            const prevDate = prev ? getPrevDay(prev.createdAt) : 0;

            return (
              <Fragment key={item.nodeId}>
                {getPrevDay(item.createdAt) !== prevDate && (
                  <Divider label={Ago.inWords(new Date(item.createdAt))} />
                )}
                <Item item={item} />
              </Fragment>
            );
          })}
      </Modal>
    </>
  );
}
