/**
 * @return {string}
 */
import dateFromUTC from './dateFromUTC';
import padZero from './padZero';

function FormatDate() {
  throw new Error('Using old formatDate!');
}

const format = (opts, date, fromUTC = false) =>
  new Intl.DateTimeFormat(navigator.language, opts).format(
    fromUTC ? dateFromUTC(date) : new Date(date)
  );

FormatDate.date = format.bind(null, {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
});

FormatDate.dateShort = format.bind(null, {
  year: '2-digit',
  month: 'numeric',
  day: 'numeric',
});

FormatDate.dateFull = format.bind(null, {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});

FormatDate.dateFullLong = format.bind(null, {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  weekday: 'long',
});

FormatDate.dateDateMonth = format.bind(null, { day: 'numeric', month: 'long' });

FormatDate.dateDayDate = format.bind(null, { weekday: 'long', day: 'numeric' });

FormatDate.dateMonth = format.bind(null, { month: 'long' });

FormatDate.dateMonthShort = format.bind(null, {
  day: 'numeric',
  month: 'short',
});

FormatDate.dateMonthYear = format.bind(null, {
  year: 'numeric',
  month: 'long',
});

FormatDate.time = format.bind(null, { hour: 'numeric', minute: 'numeric' });

FormatDate.dateTime = format.bind(null, {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
});

FormatDate.forServer = (date) => {
  date = new Date(date);
  return (
    date.getFullYear() +
    '-' +
    padZero(date.getMonth() + 1) +
    '-' +
    padZero(date.getDate())
  );
};

/** @returns yyyy-mm-dd accounting timezone */
FormatDate.forServer2 = (date) => {
  const parts = new Intl.DateTimeFormat(navigator.language, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).formatToParts(date);

  const year = parts.find((p) => p.type === 'year').value;
  const month = parts.find((p) => p.type === 'month').value;
  const day = parts.find((p) => p.type === 'day').value;

  return `${year}-${month}-${day}`;
};

/** @returns hh:mm */
FormatDate.time2 = (date) => {
  return date instanceof Date
    ? date.toTimeString().match(/\d\d:\d\d/)[0]
    : date.match(/\d\d:\d\d/)[0];
};

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export default FormatDate;
