import React from 'react';

export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <defs>
      <clipPath id="a">
        <path d="M1600,0 L1600,1250 L0,1250 L0,0 L1600,0 Z" />
      </clipPath>
      <clipPath id="b">
        <path d="M15,0 C23.2842712,-1.52179594e-15 30,6.71572875 30,15 C30,23.2842712 23.2842712,30 15,30 C6.71572875,30 1.01453063e-15,23.2842712 0,15 C-1.01453063e-15,6.71572875 6.71572875,1.52179594e-15 15,0 Z" />
      </clipPath>
      <clipPath id="c">
        <path d="M3.15,0 C4.09367,0 4.762135,0.05026 5.22466,0.110628 C5.823825,0.188825 6.216245,0.6559385 6.25289,1.259062 C6.279105,1.690423 6.3,2.30069 6.3,3.15 C6.3,3.99931 6.279105,4.60957 6.25289,5.040945 C6.216245,5.644065 5.823825,6.111175 5.22466,6.189365 C4.762135,6.24974 4.09367,6.3 3.15,6.3 C2.20633,6.3 1.537865,6.24974 1.075333,6.189365 C0.4761855,6.111175 0.083741,5.644065 0.0471065,5.040945 C0.0238167222,4.65750056 0.00471104321,4.13271488 0.000752864883,3.42433216 L0,3.15 C0,2.30069 0.0209055,1.690423 0.0471065,1.2590585 C0.083741,0.6559385 0.4761855,0.188825 1.075333,0.110628 C1.537865,0.05026 2.20633,0 3.15,0 Z" />
      </clipPath>
      <clipPath id="d">
        <path d="M3.15,0 C4.09367,0 4.762135,0.05026 5.22466,0.110635 C5.823825,0.188825 6.216245,0.655935 6.25289,1.259055 C6.279105,1.69043 6.3,2.30069 6.3,3.15 C6.3,3.99931 6.279105,4.60957 6.25289,5.040945 C6.216245,5.644065 5.823825,6.111175 5.22466,6.189365 C4.762135,6.24974 4.09367,6.3 3.15,6.3 C2.20633,6.3 1.537865,6.24974 1.075333,6.189365 C0.4761855,6.111175 0.083741,5.644065 0.0471065,5.040945 C0.0209055,4.60957 0,3.99931 0,3.15 L0.000752864883,2.87566784 C0.00471104321,2.16728512 0.0238167222,1.64249944 0.0471065,1.259055 C0.083741,0.655935 0.4761855,0.188825 1.075333,0.110635 C1.537865,0.05026 2.20633,0 3.15,0 Z" />
      </clipPath>
      <clipPath id="e">
        <path d="M5.040945,0.0471065 C5.644065,0.083741 6.111175,0.4761855 6.189365,1.075333 C6.24974,1.537865 6.3,2.20633 6.3,3.15 C6.3,4.09367 6.24974,4.762135 6.189365,5.22466 C6.111175,5.823825 5.644065,6.216245 5.040945,6.25289 C4.60957,6.279105 3.99931,6.3 3.15,6.3 C2.30069,6.3 1.69043,6.279105 1.259055,6.25289 C0.655935,6.216245 0.188825,5.823825 0.110635,5.22466 C0.05026,4.762135 0,4.09367 0,3.15 C0,2.20633 0.05026,1.537865 0.110635,1.075333 C0.188825,0.476182 0.655935,0.083741 1.259055,0.0471065 C1.64249944,0.0238167222 2.16728512,0.00471104321 2.87566784,0.000752864883 L3.15,0 C3.99931,0 4.60957,0.0209055 5.040945,0.0471065 Z" />
      </clipPath>
      <clipPath id="f">
        <path d="M3.42433216,0.000752524005 C4.13271488,0.00470901235 4.65750056,0.0238077778 5.040945,0.04711 C5.644065,0.083755 6.111175,0.476175 6.189365,1.07534 C6.24974,1.537865 6.3,2.20633 6.3,3.15 C6.3,4.09367 6.24974,4.762135 6.189365,5.22466 C6.111175,5.823825 5.644065,6.216245 5.040945,6.25289 C4.60957,6.279105 3.99931,6.3 3.15,6.3 C2.30069,6.3 1.69043,6.279105 1.259055,6.25289 C0.655935,6.216245 0.188825,5.823825 0.110635,5.22466 C0.05026,4.762135 0,4.09367 0,3.15 C0,2.20633 0.05026,1.537865 0.110635,1.07534 C0.188825,0.476175 0.655935,0.083755 1.259055,0.04711 C1.69043,0.020895 2.30069,0 3.15,0 Z" />
      </clipPath>
    </defs>
    <g clipPath="url(#a)" transform="translate(-62 -67)">
      <g transform="translate(62 67)">
        <g clipPath="url(#b)">
          <polygon fill="#3F494F" points="0 0 30 0 30 30 0 30 0 0" />
        </g>
        <g transform="translate(8 8)">
          <g clipPath="url(#c)">
            <polygon fill="#8796A1" points="0 0 6.3 0 6.3 6.3 0 6.3 0 0" />
          </g>
          <g clipPath="url(#d)" transform="translate(0 7.7)">
            <polygon fill="#8796A1" points="0 0 6.3 0 6.3 6.3 0 6.3 0 0" />
          </g>
          <g clipPath="url(#e)" transform="translate(7.7)">
            <polygon fill="#8796A1" points="0 0 6.3 0 6.3 6.3 0 6.3 0 0" />
          </g>
          <g clipPath="url(#f)" transform="translate(7.7 7.7)">
            <polygon fill="#8796A1" points="0 0 6.3 0 6.3 6.3 0 6.3 0 0" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
