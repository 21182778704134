import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

const GET_ENABLED_QUERY = gql`
  query GetEnabledQuery {
    currentApplication {
      id
      fieldwizEnabled
    }
  }
`;

export default function useFieldwizEnabled() {
  const { loading, data } = useQuery(GET_ENABLED_QUERY);

  return loading || !data || !data.currentApplication
    ? false
    : data.currentApplication.fieldwizEnabled;
}
