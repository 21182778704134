import React, { Component, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.nomodule.less';
import 'intersection-observer';
import App from './App/App';

import { ApolloProvider, ApolloConsumer } from '@apollo/client';
import ScrollMemory from './_components/ScrollMemory';
import Helmet from './_components/Helmet';
import asyncComponent from './_helpers/asyncComponent';
import { INVISIBLE_SPACE, LS } from './consts';
import getApplication from './_helpers/getApplication';
import { withPermissions } from './_components/Permissions';
import NoAccess from './_components/NoAccess/NoAccess';
import * as Sentry from '@sentry/browser';
import mixpanel from 'mixpanel-browser';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import ErrorScreen from './_components/ErrorScreen/ErrorScreen';
import client from './client';
import history from './history';
import { useLocation } from 'react-router';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import localeData from 'dayjs/plugin/localeData';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(weekday);
dayjs.extend(dayOfYear);
dayjs.extend(localeData);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

// Dev
const __DEV__ = process.env.NODE_ENV === 'development';

// Routes
const Login = asyncComponent(() => import('./Auth/Login.js'));
const Forgot = asyncComponent(() => import('./Auth/Forgot.js'));
const Reset = asyncComponent(() => import('./Auth/Reset.js'));
const Register = asyncComponent(() => import('./Auth/Register.js'));
const Set = asyncComponent(() => import('./Auth/Set.js'));
const PasswordSet = asyncComponent(() => import('./Auth/PasswordSet.js'));
const PasswordReset = asyncComponent(() => import('./Auth/PasswordReset.js'));
const ConnectedToPolar = asyncComponent(() =>
  import('./Auth/ConnectedToPolar.js')
);

const Dashboard = asyncComponent(() => import('./Dashboard/Dashboard.js'));
const Athletes = asyncComponent(() => import('./Athletes/Athletes.js'));
const AthletesBySport = asyncComponent(() =>
  import('./AthletesBySport/index.js')
);
const Athlete = asyncComponent(() => import('./Athlete/Athlete.js'));
const Messages = asyncComponent(() => import('./Messages/Messages.js'));
const Schedule = asyncComponent(() => import('./Schedule/Schedule.js'));
const ScheduleEvent = asyncComponent(() => import('./Schedule/ScheduleEvent'));
const Meal = asyncComponent(() => import('./Meal/Meal.js'));
const Appointment = asyncComponent(() =>
  import('./Appointment/Appointment.js')
);
const Training = asyncComponent(() => import('./Training/Training.js'));
const InjuryAnalysis = asyncComponent(() =>
  import('./InjuryAnalysis/InjuryAnalysis.js')
);
// const Load = asyncComponent(() => import("./Load/Router.js"));
const Load = asyncComponent(() => import('./Load'));
const Wellness = asyncComponent(() => import('./Wellness/Wellness.js'));
const Profiling = asyncComponent(() => import('./Profiling/Profiling.js'));
const AnnualPlan = asyncComponent(() => import('./AnnualPlan/AnnualPlan.js'));
const Settings = asyncComponent(() => import('./Settings/Settings.js'));
const Nomination = asyncComponent(() => import('./Nomination/Nomination.js'));
const Forms = asyncComponent(() => import('./Forms/Router.js'));
const ScreeningReports = asyncComponent(() =>
  import('./ScreeningReports/ScreeningReports.js')
);
const VideoAnalysis = asyncComponent(() =>
  import('./VideoAnalysis/VideoAnalysis.js')
);
const Workout = asyncComponent(() => import('./Workout/Workout.js'));
const Files = asyncComponent(() => import('./Files/Files.js'));

const Customers = asyncComponent(() => import('./Customers'));
const Exercises = asyncComponent(() => import('./Exercises/Exercises.js'));
const Ingredients = asyncComponent(() =>
  import('./Ingredients/Ingredients.js')
);
const Screenings = asyncComponent(() => import('./Screenings/Screenings.js'));
const BodyParts = asyncComponent(() => import('./BodyParts/Router.js'));
const Illnesses = asyncComponent(() => import('./Illnesses/Illnesses.js'));
const Taxonomies = asyncComponent(() => import('./Taxonomies/Taxonomies.js'));
const Tags = asyncComponent(() => import('./Tags'));
const WellnessScreenings = asyncComponent(() => import('./WellnessScreenings'));

const Asi = asyncComponent(() => import('./Asi/Router'));
const Onboarding = asyncComponent(() => import('./Onboarding/Onboarding'));

const ShareLink = asyncComponent(() => import('./ShareLink'));
const Reporting = asyncComponent(() => import('./Reporting'));
const CompReports = asyncComponent(() =>
  import('./CompReports/CompReports.js')
);
const Debug = asyncComponent(() => import('./Debug'));

// Notice: See `_helpers/getApplication` for switching between Super / Normal

if (!__DEV__) {
  mixpanel.init(
    '86859309aa670c0b873d7d0075e1928b',
    { api_host: 'https://api-eu.mixpanel.com' },
    ''
  );
  Sentry.init({
    release: require('../package.json').version,
    dsn: 'https://a4c206abf38845aebf44c38476a1c8d4@sentry.io/1450380',
  });
} else {
  window.dayjs = dayjs;
}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    const { error } = this.state;

    if (error) {
      //render fallback UI
      return <ErrorScreen message={error.toString()} stack={error.stack} />;
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}

const AuthenticatedRoute = withPermissions(
  ({ _can, can, __hasPermissions, component: Component, ...rest }) =>
    _can(can) ? (
      <Route
        {...rest}
        render={(props) => (
          <ApolloConsumer>
            {(client) => {
              if (!!localStorage.getItem(LS.TOKEN))
                return <Component {...props} />;

              client.resetStore();

              return (
                <Redirect
                  to={{
                    pathname: '/login',
                    state: { from: props.location },
                  }}
                />
              );
            }}
          </ApolloConsumer>
        )}
      />
    ) : (
      __hasPermissions && <Route {...rest} render={() => <NoAccess />} />
    ),
  '_can'
);

const NotAuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <ApolloConsumer>
        {() => {
          if (!localStorage.getItem(LS.TOKEN)) return <Component {...props} />;

          return <Redirect to="/" />;
        }}
      </ApolloConsumer>
    )}
  />
);

class CustomRouter extends BrowserRouter {
  history;
}

const routes = (isParent, application) => {
  let routes;

  const shared = [
    { exact: true, path: '/', component: Dashboard },

    { auth: false, path: '/login', component: Login },
    { auth: false, path: '/forgot', component: Forgot },
    { auth: false, path: '/reset', component: Reset },
    { auth: false, path: '/set', component: Set },
    { auth: false, path: '/password-set', component: PasswordSet },
    { auth: false, path: '/password-reset', component: PasswordReset },
    { auth: false, path: '/share', component: ShareLink },
    {
      auth: false,
      path: '/connected-to-polar',
      component: ConnectedToPolar,
    },

    { can: 'access:settings', path: '/settings', component: Settings },
    { can: 'access:forms', path: '/forms', component: Forms },
    { can: 'access:onboarding', path: '/onboarding', component: Onboarding },
  ];

  if (isParent) {
    routes = [
      ...shared,
      {
        path: '/schedule',
        component: Schedule,
        exact: true,
      },
      {
        path: '/athletes/:sportId([0-9A-F]{8}-[0-9A-F]{4}-[14][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})?',
        component: AthletesBySport,
      },
      {
        path: '/messages',
        component: Messages,
      },
      {
        path: '/files',
        component: Files,
      },
    ];

    if (application.features['/reporting'].isEnabled)
      routes.push({ path: '/reporting', component: Reporting });

    if (application.features['/comp-reports']?.isEnabled)
      routes.push({ path: '/comp-reports', component: CompReports });

    if (application?.features['/nomination']?.isEnabled)
      routes.push({ path: '/nomination', component: Nomination });
  } else {
    switch (getApplication()) {
      case 'admin':
        routes = [
          ...shared,
          { path: '/customers', component: Customers },
          { path: '/exercises/:type?', component: Exercises },
          { path: '/ingredients', component: Ingredients },
          { path: '/screenings', component: Screenings },
          { path: '/body-parts', component: BodyParts },
          { path: '/illnesses', component: Illnesses },
          { path: '/taxonomies/:taxonomy?', component: Taxonomies },
          { path: '/tags', component: Tags },
          { path: '/wellness', component: WellnessScreenings },
        ];
        break;
      case 'register':
        routes = [{ auth: false, path: '/', component: Register }];
        break;
      default:
        routes = [
          ...shared,
          {
            can: 'access:athletes',
            path: '/athletes/:athleteId([0-9A-F]{8}-[0-9A-F]{4}-[14][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})',
            component: Athlete,
          },
          {
            can: 'access:athletes',
            path: '/athletes',
            component: Athletes,
          },
          {
            can: 'access:workouts',
            path: `/workout/:workoutId${INVISIBLE_SPACE}:timestamp`,
            component: Workout,
          },
          {
            can: 'access:messages',
            path: '/messages',
            component: Messages,
          },
          {
            can: 'access:schedule',
            path: '/schedule',
            component: Schedule,
            exact: true,
          },
          {
            can: 'access:schedule',
            path: [
              `/schedule/:sessionType/:sessionId${INVISIBLE_SPACE}:timestamp`,
            ],
            component: ScheduleEvent,
          },
          // {
          //   can: 'access:schedule',
          //   path: `/schedule/meal/:mealId${INVISIBLE_SPACE}:timestamp`,
          //   component: Meal,
          // },
          // {
          //   can: 'access:schedule',
          //   path: `/schedule/game/:appointmentId${INVISIBLE_SPACE}:timestamp`,
          //   component: Appointment,
          // },
          // {
          //   can: 'access:schedule',
          //   path: `/schedule/appointment/:appointmentId${INVISIBLE_SPACE}:timestamp`,
          //   component: Appointment,
          // },
          // {
          //   can: 'access:schedule',
          //   path: `/schedule/training/:trainingId${INVISIBLE_SPACE}:timestamp`,
          //   component: Training,
          // },
          {
            can: 'access:injuries',
            path: '/injuries',
            component: InjuryAnalysis,
          },
          {
            can: 'access:loadWellness',
            path: '/load',
            component: Load,
          },
          {
            can: 'access:loadWellness',
            path: '/wellness',
            component: Wellness,
          },
          {
            can: 'access:calibrate',
            path: '/profiling',
            component: Profiling,
          },
          {
            can: 'access:reports',
            path: ['/reports', '/screening'],
            component: ScreeningReports,
          },
          {
            can: 'access:video',
            path: '/video',
            component: VideoAnalysis,
          },
          { can: 'access:files', path: '/files', component: Files },
          { can: 'access:asi', path: '/asi', component: Asi },
        ];
    }

    if (application?.parentFeatures?.['/nomination']?.isEnabled)
      routes.push({ path: '/nomination', component: Nomination });
  }

  if (application?.parentIsAnnualPlanEnabled)
    routes.push({ path: '/annual-plan', component: AnnualPlan });

  if (__DEV__) routes.push({ path: '/debug', component: Debug });

  return routes.map((route) => {
    if (route.path === '/share')
      return <ShareLink key={route.path} {...route} />;

    const Elem =
      route.auth === false ? NotAuthenticatedRoute : AuthenticatedRoute;

    const props = { ...route };
    delete props.auth;

    return <Elem {...props} key={props.path} />;
  });
};

const RouteTracker = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    !__DEV__ && mixpanel.track('page', location);
  }, [location]);

  return children;
};

const Application = () => (
  <ApolloProvider client={client}>
    <CustomRouter>
      <RouteTracker>
        <App history={history}>
          {(isParent, application) => (
            <>
              <ScrollMemory />
              <Switch>
                {routes(isParent, application)}

                <Route
                  render={() => [
                    <Helmet key="helmet" title="Page not found" />,
                    <h1 key="header">Page not found!</h1>,
                  ]}
                />
              </Switch>
            </>
          )}
        </App>
      </RouteTracker>
    </CustomRouter>
  </ApolloProvider>
);

ReactDOM.render(
  __DEV__ ? (
    <Application />
  ) : (
    <ErrorBoundary>
      <Application />
    </ErrorBoundary>
  ),
  document.getElementById('root')
);
// registerServiceWorker();
// unregister();
