import React from 'react';

export default function CollapseHorizontalIcon({}) {
  return (
    <svg width="18px" height="18px" viewBox="0 0 18 18">
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g transform="translate(-28, -960)" stroke="#8A96A0" strokeWidth="1.5">
          <g transform="translate(20, 954)">
            <g transform="translate(9, 7)">
              <path d="M8,16 C12.4189091,16 16,12.4189091 16,8 C16,3.58109091 12.4189091,0 8,0 C3.58254545,0 0,3.58109091 0,8 C0,12.4189091 3.58254545,16 8,16 Z"></path>
              <polyline points="8.90909091 10.9090909 6 8 8.90909091 5.09090909"></polyline>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
