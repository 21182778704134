import React, { useEffect, useMemo, useState } from 'react';
import css from './styles.less';
import OrgSwitch from '../../_icons/OrgSwitch';
import { useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import Modal from 'react-modal';
import Image from '../Image';
import { TLD } from '../../consts';
import fuzzy from '../../_helpers/fuzzy';
import cls from '../../_helpers/cls';
import { Link } from '../../App/App';
import { EventSubscribe, EventTypes } from '../../Events';
import authAndRedirect from '../../_helpers/authAndRedirect';

export default function OrgSwitcher() {
  const [open, setOpen] = useState(false),
    [query, setQuery] = useState(''),
    [tab, setTab] = useState('orgs');

  const { data, loading } = useQuery(gql`
    query GetOrgs {
      currentApplication {
        id
      }
      currentOrParentApplication {
        id
        slug
        applicationsByParentId(orderBy: [NAME_ASC]) {
          nodes {
            id
            name
            slug
            assetByLogo {
              x1: url(width: 100, height: 100, fit: IN, trim: true)
              x2: url(width: 200, height: 200, fit: IN, trim: true)
            }
          }
        }
      }
      allSports(
        #filter: { personSportsBySportIdExist: true }
        orderBy: NAME_ASC
      ) {
        nodes {
          id
          name
          assetByIcon {
            x1: url(width: 100, height: 100, fit: IN)
            x2: url(width: 200, height: 200, fit: IN)
          }
          #personSportsBySportId {
          #  totalCount
          #}
        }
      }
    }
  `);

  const [changeOrg] = useMutation(gql`
    mutation SwitchOrg($id: UUID!) {
      switchApplication(input: { applicationId: $id }) {
        jwtToken
      }
    }
  `);

  const [applications, sports] = useMemo(() => {
    return [
      data?.currentOrParentApplication?.applicationsByParentId?.nodes?.filter(
        (org) => {
          if (!query) return true;
          return fuzzy(query, org.name, false);
        }
      ) ?? [],
      data?.allSports?.nodes?.filter((sport) => {
        if (!query) return true;
        return fuzzy(query, sport.name, false);
      }) ?? [],
    ];
  }, [data, query]);

  useEffect(
    () =>
      EventSubscribe(EventTypes.ShowSportsSwitcher, () => {
        setTab('sports');
        setOpen(true);
      }),
    []
  );

  if (loading) return null;

  const current = data?.currentApplication;
  const parent = data?.currentOrParentApplication;
  const isParent = current?.id === parent?.id;

  const onOpenClick = () => setOpen(true),
    onRequestClose = () => setOpen(false),
    onSearch = (e) => setQuery(e.target.value.trim());

  const onSwitchClick = (org) => async () => {
    const { id, slug } = org;

    const { data } = await changeOrg({ variables: { id } });
    await authAndRedirect(
      data?.switchApplication?.jwtToken,
      `https://${slug}${TLD}`
    );
  };

  const onSportClick = (sportId) => async (e) => {
    if (isParent) return onRequestClose();
    e.preventDefault();

    const { id, slug } = parent;
    const { data } = await changeOrg({ variables: { id } });
    await authAndRedirect(
      data?.switchApplication?.jwtToken,
      `https://${slug}${TLD}/athletes/${sportId}`
    );
  };

  return (
    <>
      <button className={css.switchBtn} onClick={onOpenClick} type="button">
        {OrgSwitch}
      </button>
      <Modal
        isOpen={open}
        onRequestClose={onRequestClose}
        className={css.modal}
        closeTimeoutMS={300}
        overlayClassName={{
          base: css.overlay,
          afterOpen: css.overlayOpen,
          beforeClose: css.overlayClose,
        }}
      >
        <header className={css.header}>
          <input
            type="search"
            placeholder={`Search ${tab === 'sports' ? 'Sports' : 'Sites'}`}
            onInput={onSearch}
          />
          <div
            className={cls(css.tabs, {
              [css.flip]: tab === 'sports',
            })}
          >
            <button onClick={() => setTab('orgs')}>Sites</button>
            <button onClick={() => setTab('sports')}>Sports</button>
          </div>
        </header>
        {tab === 'orgs' ? (
          <ul className={css.orgs}>
            {applications.map((org) => {
              let logo = org.name
                .match(/(^\w\w?|\s\w)?/g)
                .map((v) => v.trim())
                .join('')
                .match(/(^\w|\w$)?/g)
                .join('')
                .toLocaleUpperCase();

              if (org.assetByLogo) {
                const { x1, x2 } = org.assetByLogo;

                logo = (
                  <Image
                    src={x1}
                    srcSet={`${x1} 1x, ${x2} 2x`}
                    alt={org.name}
                    width={100}
                    height={100}
                  />
                );
              }

              return (
                <li
                  key={org.id}
                  className={current?.id === org.id ? css.current : ''}
                >
                  <button type="button" onClick={onSwitchClick(org)}>
                    <span>{logo}</span>
                    <strong>{org.name}</strong>
                  </button>
                </li>
              );
            })}
          </ul>
        ) : tab === 'sports' ? (
          <ul className={css.orgs}>
            <li>
              <Link type="button" to="athletes" onClick={onSportClick()}>
                <span>*</span>
                <strong>All Sports</strong>
                <small>&nbsp;</small>
              </Link>
            </li>
            {sports.map((sport) => {
              let logo = sport.name
                .match(/(^\w\w?|\s\w)?/g)
                .map((v) => v.trim())
                .join('')
                .match(/(^\w|\w$)?/g)
                .join('')
                .toLocaleUpperCase();

              if (sport.assetByIcon) {
                const { x1, x2 } = sport.assetByIcon;

                logo = (
                  <Image
                    src={x1}
                    srcSet={`${x1} 1x, ${x2} 2x`}
                    alt={sport.name}
                    width={100}
                    height={100}
                  />
                );
              }

              return (
                <li key={sport.id}>
                  <Link
                    type="button"
                    to={`/athletes/${sport.id}`}
                    onClick={onSportClick(sport.id)}
                  >
                    <span>{logo}</span>
                    <strong>{sport.name}</strong>
                    {/*<small>
                      {sport.personSportsBySportId.totalCount ?? 0} Athletes
                    </small>*/}
                  </Link>
                </li>
              );
            })}
          </ul>
        ) : null}
      </Modal>
    </>
  );
}
